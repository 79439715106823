<template>
  <floating-vue-dropdown
    compute-transform-origin
    eager-mount
    handle-resize
    :placement="placement"
    :popper-class="fromPopupLocal ? 'v-popup-popper' : ''"
    :shown="controlShown!.state"
    :skidding="skidding"
    :theme="theme"
    :triggers="controlShown?.control ? [] : ['click']"
  >
    <slot name="hoverable">
      <span class="v-popover-icon">
        <icon-menu-info />
      </span>
    </slot>

    <template #popper>
      <slot name="content" />
    </template>
  </floating-vue-dropdown>
</template>

<script setup lang="ts">
import type { PopoverPlacement } from '~types/props'

import { Dropdown as FloatingVueDropdown } from 'floating-vue'

withDefaults(
  defineProps<{
    theme?: string
    placement?: PopoverPlacement
    skidding?: number
    controlShown?: {
      control: boolean
      state: boolean
    }
  }>(),
  {
    theme: 'product-dropdown',
    placement: 'bottom',
    skidding: 0,
    controlShown: () => {
      return {
        control: false,
        state: false
      }
    }
  }
)

const { fromPopup } = useInstance()
const fromPopupLocal = fromPopup.value
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popper__popper {
  z-index: 1015;

  &.v-popup-popper {
    z-index: 10000;
  }
}
.v-popper--theme-delivery-and-pay {
  .v-popper__inner {
    background: variables.$PopupBackground;
    color: variables.$PopupColor;
    border-radius: 5px;
    padding: 12px 24px 12px 12px;
    position: relative;
    .v-d-inline {
      display: inline;
    }
    .v-close {
      position: absolute;
      top: 0;
      right: -12px;
    }
  }
  .v-popper__arrow-outer {
    border-color: variables.$PopupBackground;
  }
  .v-popper__arrow-inner {
    border-color: variables.$PopupBackground;
  }
}

.v-popper--theme-product-dropdown {
  .v-popper__inner {
    background: variables.$PopupColor;
    color: variables.$PopupBackground;
    border-radius: 5px;
    padding: 7px 12px 6px;
  }

  .v-popper__arrow-inner {
    border-color: variables.$PopupColor;
  }
}

.v-popper__wrapper {
  transition: transform 0.15s;
  .v-popper__inner {
    overflow: hidden;
  }
}

.v-popper__popper.v-popper__popper--hidden .v-popper__wrapper {
  transform: scale(0.5);
}

.v-popper__inner {
  max-width: 320px;
}
</style>
